@font-face {
	font-family: 'ToyotaType';
	font-weight: normal;
	src: url('./fonts/ToyotaType-Regular.otf');
}

@font-face {
	font-family: 'ToyotaType';
	font-weight: bold;
	src: url('./fonts/ToyotaType-Semibold.otf');
}
$grey: #282830;

body {
	margin: 0;
	padding: 0rem;
	background: #ebebeb;
	font-family: 'ToyotaType', sans-serif;
}

.red-line {
	position: absolute;
	top: 0;
	right: 0;
	width: 33%;
	height: 7px;
	background: red;
	z-index: 999999;
}

.upper {
	position: relative;
	background: $grey;
	padding: 20px;
	.help {
		color: #fff;
		text-decoration: none;
		position: absolute;
		top: 20px;
		right: 20px;
		border-radius: 20px;
		border: 2px solid #fff;
		padding: 0.3rem 1rem;
		font-weight: bold;
		transition: all 0.3s ease;

		&:hover {
			color: $grey;
			background: #fff;
			transition: all 0.3s ease;
		}
	}
	.content {
		padding-top: 10vh;
		max-width: 1200px;
		margin: 0 auto;
		color: #fff;
		display: grid;
		grid-template-columns: 8fr 4fr;
		@media (max-width: 800px) {
			padding-top: 5vh;
			grid-template-columns: 1fr;
		}
		h1 {
			font-weight: normal;
			text-transform: uppercase;
			font-size: 60px;
			line-height: 60px;
			margin-top: 0;
			@media (max-width: 800px) {
				font-size: 40px;
				line-height: 40px;
			}
		}
		.sub {
			font-size: 24px;
			@media (max-width: 800px) {
				font-size: 16px;
				line-height: 16px;
			}
		}
		p {
			line-height: 34px;
		}
		img {
			margin-bottom: -200px;
			width: 100%;
		}
	}
}
.codes {
	max-width: 1200px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 4fr 4fr 4fr;
	padding-top: 25px;
	@media (max-width: 800px) {
		grid-template-columns: 1fr;
		padding-top: 250px;
	}
	div {
		text-align: center;
		canvas {
			max-width: 200px;
			padding: 10px;
			box-sizing: border-box;
			background: #fff;
		}
	}
	p.head {
		font-weight: bold;
	}
}

.centerCenter {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
